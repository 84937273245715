export default class TemplatesAPI {
    constructor(service, api) {
        this.service = service;

        this.api = api;
    }

    create({name, description, json, content, type, params}) {
        return this.service.post("/templates", {
            body: JSON.stringify({
                name, description, json, content, type, params
            })
        }).then(res => res.json());
    }

    get(id) {
        if (typeof id == "undefined") {
            return this.service.get("/templates").then(res => res.json());
        } else {
            return this.service.get("/templates/" + id).then(res => res.json());
        }
    }

    delete(id) {
        return this.service.delete("/templates/" + id);
    }

    update(id, config) {
        return this.service.patch("/templates/" + id, {
            body: JSON.stringify(config)
        }).then(res => res.json());
    }

    renderTemplate(id, json, log = false) {
        if (json) {
            return this.service.post(`/templates/${id}/render?log=${log}`, {
                body: JSON.stringify(json)
            }).then(res => log ? res.json() : res.text());
        }
        return this.service.get(`/templates/${id}/render`).then(res => res.text());
    }

    getPDF(id, json) {
        if (json) {
            return this.service.post("/templates/" + id + "/generate", {
                body: JSON.stringify(json)
            }).then(res => res.arrayBuffer());
        }
        
        return this.service.get("/templates/" + id + "/generate").then(res => res.arrayBuffer());
    }
}
