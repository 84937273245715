import React, { useState, useEffect } from "react";
import FilesList from "./FilesList";

import { withAPIAccess } from "../api/APIAccess";
import { useHistory } from "react-router-dom";

import withUser from "../login/UserAccess";

import AppToolbar from "../common/AppToolbar";

export default withUser(withAPIAccess(function Files(props) {
    const { api, user } = props;

    if (!user?.profile)
        return null;

    return [
    <AppToolbar title={"Files"} headerControls={[]}/>,
    <FilesList 
        title="Uploaded"
        fullWidth={false}
    />]
}));
