import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

import SortableTable from "../common/SortableTable";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(5, 5),
        display: "flex",
        flex: "1 1 auto",
        alignItems: "center",
        paddingBottom: theme.spacing(3),
        //background: theme.palette.primary.main,
        //color: "white"
    },
    title: {
        flexGrow: 1,

    }
}));

export default function TopBar(props) {
    const classes = useStyles();

    return <div className={classes.root}>
        <Typography variant="h4" component="h1" align="left" className={classes.title}>
            {props.title}
        </Typography>
        {props.header}
    </div>
}
