import React, { useState, useEffect } from "react";

import { makeStyles } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';

import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

import TextField from '@material-ui/core/TextField';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import InputLabel from '@material-ui/core/InputLabel';

import Select from '@material-ui/core/Select';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import Button from '@material-ui/core/Button';

import Checkbox from '@material-ui/core/Checkbox';

import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator';


import { withAPIAccess } from "../api/APIAccess";

import DialogBox from "./DialogBox";

import SortableTableWithHeader from "../common/SortableTableWithHeader";

import {
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import DialogBoxGenerator from "./DialogBoxGenerator";


const useStyles = makeStyles(theme => ({
    fab: {
        position: "relative",
        top: "26px"
    }
}));


export default function SortableTableCRUD(props) {
    const classes = useStyles();

    const [openDialog, setOpenDialog] = useState(false);

    const handleClose = values => {
        setOpenDialog(false);
        if (values) {
            props.onDialogAdd(values);
        }
    }

    const add = e => {
        setOpenDialog(true);
    }

    let newProps = {...props};

    if (props.fullWidth && !props.loading) {
        newProps.headerControls = [
            ...(props.headerControls || []),
            <Button onClick={add} color="inherit">Create</Button>
        ];
    }

    return [<DialogBoxGenerator title={props.dialogTitle} fields={props.dialogFields} open={openDialog} handleClose={handleClose} onUpdate={props.dialogOnUpdate} />,
    <SortableTableWithHeader {...newProps}>
        {newProps.children}
        { !props.fullWidth ?
        <Box textAlign="center">
            <Fab color="primary" aria-label="add" className={classes.fab} onClick={add}><AddIcon /></Fab>
        </Box> : null }
    </SortableTableWithHeader>];
};
