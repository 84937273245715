import React, { useState } from "react";

import TextField from '@material-ui/core/TextField';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import InputLabel from '@material-ui/core/InputLabel';

import Select from '@material-ui/core/Select';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import Button from '@material-ui/core/Button';

import Checkbox from '@material-ui/core/Checkbox';

import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator';

import DialogBox from "./DialogBox";

import SortableTableWithHeader from "../common/SortableTableWithHeader";

import {
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

export default function DialogBoxGenerator(props) {
    const { onUpdate = () => {} } = props;
    const [values, setValues] = useState({});
    const handleChange = e => {
        const { name, value, type, checked } = e.target;

        let vals = {};

        if (type == "checkbox") {
            vals = {...values, [name]: checked};
        } else {
            vals = {...values, [name]: value };
        }

        setValues(vals);
        onUpdate(vals);
    };

    const handleDateChange = name => e => {
        let vals = {...values, [name]: e};
        setValues(vals);
        onUpdate(vals);
    }

    function createField(fields) {
        return fields.map(x => {
            if (x.type == "select") {
                return <TextField
                      id={x.id}
                      name={x.id}
                      label={x.label}
                      select
                      fullWidth
                      value={values[x.id]}
                      onChange={handleChange}
                      required={x.required}
                    >{ x.data.map(x => {
                        return <MenuItem value={x.id}>{x.label}</MenuItem>
                    }) }
                </TextField>
            } else if (x.type == "date") {
                return <KeyboardDatePicker
                  disableToolbar
                  margin="normal"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id={x.id}
                  label={x.label}
                  value={values[x.id]}
                  onChange={handleDateChange(x.id)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  fullWidth
                  required={x.required}
                />
            } else if (x.type == "checkbox") {
                return <FormControlLabel
                    control={
                        <Checkbox checked={values[x.id]} name={x.id} onChange={handleChange} value={x.id} />
                    }
                    label={x.label}
                    required={x.required}
                />
            } else {
                return <TextField
                    fullWidth
                    required={x.required}
                    id={x.id}
                    name={x.id}
                    label={x.label}
                    multiline={typeof x.multiline !== "undefined"}
                    rowsMax={x.multiline}
                    onChange={handleChange}
                    value={values[x.id]}
                    validators={['required']}
                    errorMessages={['this field is required', 'email is not valid']}
                />
            }
        })
    }

    return <DialogBox {...props} actions={[
        <Button onClick={e => props.handleClose(values)}>Add</Button>,
        <Button onClick={e => props.handleClose(null)}>Cancel</Button>,
    ]}>
        { createField(props.fields || []) }
    </DialogBox>
}
