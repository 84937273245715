import React from 'react';
import ListItem from '@material-ui/core/ListItem';

import { Link } from "react-router-dom";

const A = props => (<a {...props}>{props.children}</a>);

export default function ListItemLink(props) {
    if (props.href.indexOf("://") != -1) {
        return <ListItem button component={A} href={props.href} target="__blank" {...props}/>;
    } else {
        return <ListItem button component={Link} to={props.href} {...props}/>;
    }
}
