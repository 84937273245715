import APIService from "./APIService.js";

import Files from "./FileAPI.js";
import Templates from "./TemplatesAPI.js";
import Keys from "./KeysAPI.js";
import PDFs from "./PDFsAPI.js";


export default class APIClient {
    constructor({ oktaAuth, uri }) {
        this.oktaAuth = oktaAuth;

        this.service = new APIService(oktaAuth, uri);

        this.files = new Files(this.service, this);
        this.templates = new Templates(this.service, this);
        this.keys = new Keys(this.service, this);
        this.pdfs = new PDFs(this.service, this);
    }

    async isAdmin() {
        let user = await this.service.getUser();
        return user.groups.includes("Administrators");
    }
}
