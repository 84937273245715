import jwt_decode from "jwt-decode";

export default class APIService {
    constructor(oktaAuth, uri) {
        this.oktaAuth = oktaAuth;
        this.uri = uri;
    }

    async getUser() {
        return jwt_decode(this.oktaAuth.authState.accessToken);
    }

    async request(endpoint, config = {}) {
        let token = this.oktaAuth ? this.oktaAuth.authState.accessToken : null;
        config = {
            ...config,
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                ...(config.headers || {})
            }
        }

        let res = await fetch(this.createURL(endpoint), config);
        if (!res.ok)
            throw res;
            
        return res;
    }

    createURL(endpoint) {
        return this.uri + endpoint;
    }

    get(endpoint, config = {}) {
        return this.request(endpoint, { ...config, method: 'GET' });
    }

    post(endpoint, config = {}) {
        return this.request(endpoint, { ...config, method: 'POST' });
    }

    delete(endpoint, config = {}) {
        return this.request(endpoint, { ...config, method: 'DELETE' });
    }

    put(endpoint, config = {}) {
        return this.request(endpoint, { ...config, method: 'PUT' });
    }

    patch(endpoint, config = {}) {
        return this.request(endpoint, { ...config, method: 'PATCH' });
    }
}
