import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Box from "@material-ui/core/Box"

const useStyles = makeStyles(theme => ({
  logoWrapper: {
    zIndex: -1,
    width: "100%",
    height: "100%",
    margin: 0,
    left: 0,
    top: 0,
    position: "absolute",
    backgroundImage: 'url("/logo_blue.png")',
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    filter: "grayscale(10%) blur(5px) contrast(40%)"
  }
}))

export default function LogoBackground(props) {
  const classes = useStyles()

  return <Box className={classes.logoWrapper} />
}
