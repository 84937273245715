const parseSync = require('csv-parse/lib/sync');

const fetch = require("node-fetch");
const convert = require('xml-js');

export default class FileAPI {
    static NEEDS_UPLOADING() { return 0; }
    static AWAITING_APPROVAL() { return 1; }
    static APPROVED() { return 2; }
    static REJECTED() { return 3; }

    constructor(service, api) {
        this.service = service;

        this.api = api;
    }

    async get(prefix) {
        const signedUrl = await this.service.get("/files/" + (typeof prefix == "undefined" ? "" : "?prefix=" + prefix)).then(res => res.text());

        const data = await fetch(signedUrl).then(res => res.text()).then(text => {
            return convert.xml2js(text, {compact: true, spaces: 4});
        });

        let results = [];

        let Contents = data.ListBucketResult.Contents;
        Contents = Contents ? (Array.isArray(Contents) ? data.ListBucketResult.Contents : [data.ListBucketResult.Contents]) : [];

        for (var file of Contents) {
            results.push({ 
                displayName: file.Key._text.replace(prefix + "/", ""), 
                name: file.Key._text,
                lastModified: file.LastModified._text,
                awsETag: file.ETag._text
            });
        }

        return results;
    }

    async S3ReadLinesInFile(filename, lineCount) {
        const signedUrl = await this.service.get("/files/" + encodeURIComponent(filename)).then(res => res.text());

        const BYTES = 1024 * 5;

        let lines = [];
        let curByte = BYTES;
        let content = "";

        while (lines.length < lineCount + 1) {
            let shouldBreak = false;

            lines = await fetch(signedUrl, {
                headers: {
                    Range: "bytes=0-" + curByte
                }
            }).then(async res => {
                if (res.status == 404)
                    throw res;

                shouldBreak = res.headers.get("Content-Length") < curByte;
                return res;
            }).then(res => res.text()).then(text => {
                return parseSync(text, {
                    delimiter: filename.endsWith(".txt") ? "\t" : ",",
                    relax_column_count: true,
                    relax: true
                });
            });

            if (shouldBreak)
                break;

            curByte += BYTES;
        }

        if (lines.length > lineCount)
            lines.length = lineCount;

        return lines;
    }

    async preview(filename, lines) {
        return await this.S3ReadLinesInFile(filename, lines || 20);
    }

    async aboutFile(filename) {
        const signedUrl = await this.service.get("/files/" + encodeURIComponent(filename) + "?about=true").then(res => res.text());

        return fetch(signedUrl, {
            method: "HEAD"
        }).then(res => {
            return {
                name: filename,
                lastModified: res.headers.get("Last-Modified"),
                awsETag: res.headers.get("ETag")
            }
        });
    }

    async getFile(filename, { raw } = {}) {
        const signedUrl = await this.service.get("/files/" + encodeURIComponent(filename)).then(res => res.text());

        return raw ? fetch(signedUrl) : fetch(signedUrl).then(async res => {
            return res.text();
        });
    }

    getFileLink(filename) {
        return this.service.get("/files/" + encodeURIComponent(filename)).then(res => res.text());
    }

    async upload(key, file, uploadType) {
        const signedUrl = await this.service.post("/files/" + encodeURIComponent(key), {
            headers: {
                "Content-Type": file.type,
            }
        }).then(res => res.text());

        return fetch(signedUrl, {
            method: "PUT",
            body: file,
            headers: {
                "Content-Type": file.type,
            }
        }).then(res => res.text());
    }

    async delete(key) {
        const signedUrl = await this.service.delete("/files/" + encodeURIComponent(key)).then(res => res.text());

        return fetch(signedUrl, {
            method: "DELETE",
        }).then(res => res.text());
    }
}
