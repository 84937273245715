export default class TemplatesAPI {
    constructor(service, api) {
        this.service = service;

        this.api = api;
    }

    loadFieldsFromFile(key, objForm) {
        return this.service.get("/pdfs/" + encodeURIComponent(key) + "/fields" + (objForm ? "?objForm=true" : "")).then(res => res.json());
    }
}
