import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';


let counter = 0;

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

class SortableTableHead extends React.Component {
    constructor(props) {
        super(props);
    }

    createSortHandler(property) {
        return event => this.props.onRequestSort(event, property);
    }

    render() {
        const { mapFunction, onSelectAllClick, order, orderBy, numSelected, rowCount, headers } = this.props;

        const defaultMapFunction = row => {
            const content = row.innerRender ? row.innerRender() : <strong>{row.label}</strong>;
            if (row.outerRender)
                return row.outerRender(row);

            return <TableCell
                key={row.id}
                align="left"
                padding={row.disablePadding ? 'none' : 'default'}
                sortDirection={ orderBy === row.id ? order : false }>
                { content }
                <Tooltip
                    title="Sort"
                    placement={ row.numeric ? 'bottom-end' : 'bottom-start' }
                    enterDelay={300}>
                    <TableSortLabel
                        active={ orderBy === row.id }
                        direction={ order }
                        onClick={ this.createSortHandler(row.id) }>

                    </TableSortLabel>
                </Tooltip>

            </TableCell>
        };

        return (<TableHead>
            <TableRow>
                {headers.map(
                    mapFunction || defaultMapFunction,
                    this)}
            </TableRow>
        </TableHead>);
    }
}

SortableTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const styles = theme => ({
    root: {
        width: '100%',
    //marginTop: theme.spacing.unit * 3,
    },
    table: {
        width: "auto",
        minWidth: "100%",
        tableLayout: "fixed",
        whiteSpace: "nowrap"
    //minWidth: 1020,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    tableCell: {
        padding: "0 40px"
    },
    paper: {
        position: "relative",
        margin: theme.spacing(2),
        marginBottom: theme.spacing(5)
    },
});

class SortableTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            order: 'asc',
            orderBy: props.startOrderBy || '',
            selected: [],
            page: 0,
            rowsPerPage: props.paginationStartRows || (this.props.paginationOptions || [50])[0]
        };
    }

    handleRequestSort(event, property) {
        const orderBy = property;
        let order = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }

        this.setState({ order, orderBy });
    }

    handleSelectAllClick(event) {
        if (event.target.checked) {
            this.setState(state => ({ selected: state.data.map(n => n.id) }));
            return;
        }
        this.setState({ selected: [] });
    }

    handleClick(event, id) {
        const { selected } = this.state;
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        this.setState({ selected: newSelected });
    }

    handleChangePage(event, page) {
        this.setState({ page });
    }

    handleChangeRowsPerPage(event) {
        this.setState({ rowsPerPage: event.target.value });
    }

    isSelected(id) {
        return this.state.selected.indexOf(id) !== -1;
    }

    render() {
        const { classes, vrn, headers, data, paginate, paginationOptions, loading, text, minRows = 0 } = this.props;
        const { order, orderBy, selected, rowsPerPage, page } = this.state;
        const emptyRows = minRows - Math.min(minRows, data.length - page * minRows);

        let sorted = stableSort(data, getSorting(order, orderBy));

        if (paginate)
            sorted = sorted.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

        const content = [text ? <Box p={2}>{ text } </Box> : <div className={classes.tableWrapper}>
            <Table className={classes.table} aria-labelledby="tableTitle">
                <SortableTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={this.handleSelectAllClick.bind(this)}
                    onRequestSort={this.handleRequestSort.bind(this)}
                    rowCount={data.length}
                    headers={headers}
                />
                <TableBody>
                    {sorted.map((n, i) => {
                        return this.props.row(n, i);
                    })}
                    {emptyRows > 0 && (
                        <TableRow style={{ height: 49 * emptyRows }}>
                            <TableCell colSpan={headers.length} />
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </div>,
        paginate ? <TablePagination
                    rowsPerPageOptions={paginationOptions}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        'aria-label': 'Previous Page',
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'Next Page',
                    }}
                    onChangePage={this.handleChangePage.bind(this)}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage.bind(this)}
            /> : null
        ];

        return loading ?

            <Grid container justify="center" alignItems="center">
                <Grid item>
                    <Box mt={2} mb={2}>
                        <CircularProgress />
                    </Box>
                </Grid>
            </Grid>: content;
    }
}

export default withStyles(styles)(SortableTable);
