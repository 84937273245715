import React, { useState, useEffect } from "react";
import SortableTable from "../common/SortableTable";
import SortableTableWithHeader from "../common/SortableTableWithHeader";

import CircularProgress from '@material-ui/core/CircularProgress';

import Button from '@material-ui/core/Button';

import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { withAPIAccess } from "../api/APIAccess";
import { useHistory } from "react-router-dom";

import withFileSelector from "./FileSelector";

import DialogBox from "../common/DialogBox";

import TextField from '@material-ui/core/TextField';

import withUser from "../login/UserAccess";

import moment from "moment";

import truncate from "truncate";

const MAX_FILE_NAME_LENGTH = 50;

export default withUser(withAPIAccess(withFileSelector(function FilesList(props) {
    const { api, fullWidth, prefix, exclude, fileSelector, user, title, onChange } = props;

    const [uploading, setUploading] = useState([]);
    const [files, setFiles] = useState(null);
    const [file, setFile] = useState(null);
    const [openCreate, setOpenCreate] = useState(false);
    const [values, setValues] = useState({});

    const [anchorEl, setAnchorEl] = React.useState(null);

    const history = useHistory();

    fileSelector.onchange = e => {
        let uploadables = e.target.files;

        let tempUploads = [];
        let tempFiles = [...files];

        for (var i = 0; i < uploadables.length; i++) {
            let file = uploadables.item(i);
            let name = (prefix ? prefix + "/" : `admins/${user.profile.email}/`) + file.name;

            api.files.upload(name, file).then(() => {
                api.files.aboutFile(name).then(file => {
                    console.log(file);

                    tempFiles = [...tempFiles.filter(x => x.name != name), {...file, displayName: file.name.replace(prefix + "/", "")}];

                    tempUploads = [...tempUploads.filter(x => x.name !== name )];

                    setFiles(tempFiles);
                    setUploading(tempUploads);

                    onChange && onChange(tempFiles);
                })
            });

            tempUploads.push({ uploading: true, name });
        }

        setUploading(tempUploads);
    }

    useEffect(() => {
        Promise.all([
            api.files.get(prefix),
        ]).then(([files]) => {
            let promises = files.map(x => {
                return api.files.getFileLink(x.name);
            })
            Promise.all(promises).then(hrefs => {
                let tempFiles = files.map((x, i) => ({...x, href: hrefs[i]}));
                setFiles(tempFiles);

                onChange && onChange(tempFiles);
            });
        });
    }, []);

    const onClick = file => async e => {
        setFile(file);
        setOpenCreate(true);
    }

    const run = file => e => {
        setAnchorEl(e.target);
        setFile(file);
    }

    const upload = e => {
        fileSelector.click();
    };

    const deleteFile = file => e => {
        api.files.delete(file.name).then(() => {
            setFiles(files.filter(x => x.name != file.name));
        });
    };

    const handleChange = name => e => {
        setValues({...values, [name]: e.target.value});
    }

    return <SortableTableWithHeader
        headers={[{
            id: "filename",
            label: "Filename"
        },
        {
            id: "date",
            label: "Date created"
        },
        {
            id: "actions",
            label: "Actions"
        }]}
        loading={files === null}
        data={[...uploading, ...(files || []).filter(file => !file.name.includes(exclude))]}
        paginate={true}
        minRows={3}
        paginationStartRows={10}
        paginationOptions={[5, 10, 20, 50]}
        row={(row, index) => { 
            let name = row.name.replace(prefix + "/", "")
            if (row.uploading) {
                return <TableRow>
                    <TableCell title={name}>{truncate(name, MAX_FILE_NAME_LENGTH)}</TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <CircularProgress />
                    </TableCell>
                </TableRow>
            }
            return <TableRow>
                <TableCell title={name}>{truncate(name, MAX_FILE_NAME_LENGTH)}</TableCell>
                <TableCell>{moment(row.lastModified).format("DD-MM-YYYY HH:mm:ss")}</TableCell>
                <TableCell>
                    <Button color="primary" href={row.href}>Download</Button>
                    <Button color="primary" onClick={deleteFile(row)}>Delete</Button>
                </TableCell>
            </TableRow>
        }}
        fullWidth={typeof fullWidth == "undefined" ? true : fullWidth}
        title={title || "Files"}
        headerControls={files ? [
            <Button key="Add" onClick={upload} color="inherit">Upload</Button>
        ] : []}
    />
})));
