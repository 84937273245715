export default [
    {
        name: "Templates",
        description: "View templates",
        link: "/",
        adminLink: "/",
        updates: 0
    },
    {
        name: "Files",
        description: "View files",
        link: "/files",
        adminLink: "/files",
        updates: 0
    },
    {
        name: "Logout",
        description: "",
        adminLink: "/logout",
        link: "/logout",
        updates: 0
    }
];
