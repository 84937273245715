import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Sidebar from './sidebar/Sidebar';
import { withRouter } from "react-router";

import withUser from "./login/UserAccess";

const useStyles = drawerWidth => makeStyles(theme => ({
    root: {
        marginLeft: drawerWidth
    },
}));

export default withUser(function Container(props) {
    const { user } = props;

    const classes = useStyles(props.drawerWidth)();

    return (
        <div>
            { user.token && <Sidebar user={user} drawerWidth={props.drawerWidth} companyId={props.companyId} /> }
            <div className={user.token ? classes.root : null}>
                {props.children}
            </div>
        </div>
    )
});
