import React, { useContext } from "react"
import PropTypes from "prop-types"
import { useOktaAuth } from "@okta/okta-react"
import APIClient from "./APIClient.js"

const API_URL = process.env.REACT_APP_API_URI
const APIContext = React.createContext()

/**
 * @returns {APIClient} An `APIClient` object containing an okta auth object and api methods.
 */
export const useAPIAccess = () => {
  const api = useContext(APIContext)

  return api
}

export const withAPIAccess = Component => props => {
  const { api } = props
  if (api) return <Component {...props} />

  return (
    <APIContext.Consumer>
      {api => <Component {...props} api={api} />}
    </APIContext.Consumer>
  )
}

export const APIAccess = props => {
  const { children } = props

  const oktaAuth = useOktaAuth()

  return (
    <APIContext.Provider
      value={
        new APIClient({
          oktaAuth,
          uri: API_URL,
        })
      }
    >
      {children}
    </APIContext.Provider>
  )
}
APIAccess.propTypes = {
  children: PropTypes.node.isRequired
}
