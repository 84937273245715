import React from "react";

function buildFileSelector() {
    const fileSelector = document.createElement('input');
    fileSelector.setAttribute('type', 'file');
    fileSelector.setAttribute('multiple', '');
    return fileSelector;
}

export default function withFileSelector(Component) {
    return props => {
        const [fileSelector] = React.useState(buildFileSelector());

        return <Component {...props} fileSelector={fileSelector} />;
    }
}
