import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import Backdrop from "@material-ui/core/Backdrop"
import CircularProgress from "@material-ui/core/CircularProgress"

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  }
}))

const CircularLoader = props => {
  const classes = useStyles()

  const {
    color = "inherit",
    open: defaultOpen = false,
    closeOnClick = false,
    transitionDuration
  } = props

  const [isOpen, setOpen] = React.useState(!!defaultOpen)

  useEffect(() => setOpen(!!defaultOpen), [defaultOpen])

  const handleOnClick = () => {
    if (closeOnClick) setOpen(false)
  }

  return (
    <Backdrop
      className={classes.backdrop}
      open={isOpen}
      onClick={handleOnClick}
      transitionDuration={transitionDuration}
    >
      <CircularProgress color={color} />
    </Backdrop>
  )
}

CircularLoader.propTypes = {
  color: PropTypes.oneOf(["inherit", "primary", "secondary"]),
  open: PropTypes.bool.isRequired,
  closeOnClick: PropTypes.bool,
  transitionDuration: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({
      appear: PropTypes.number,
      enter: PropTypes.number,
      exit: PropTypes.number
    })
  ])
}

export default CircularLoader
