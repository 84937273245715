import Typography from "@material-ui/core/Typography"
import React from "react"
import Link from "@material-ui/core/Link"

export default class Copyright extends React.Component {
  render() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {"Copyright © "}
        <Link color="inherit" href="https://www.simplyvat.com">
          SimplyVAT&#8482;
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    )
  }
}
