import React, { useState } from "react"
import PropTypes from "prop-types"
import OktaAuth from "@okta/okta-auth-js"
import { useOktaAuth } from "@okta/okta-react"
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator"
import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Section from "../common/Section"
import LogoBackground from "../common/LogoBackground"
import CircularLoader from "../common/CircularLoader"
import Copyright from "../common/Copyright"
import Link from "../common/Link"
import SnackbarUtils from "../common/SnackbarUtils"

const useStyles = makeStyles(theme => ({
  formField: {
    margin: theme.spacing(1, 0)
  },
  submitButton: {
    margin: theme.spacing(3, 0, 2)
  },
  linksContainer: {
    textAlign: "left"
  }
}))

const LoginForm = ({ issuer }) => {
  const classes = useStyles()

  const { authService } = useOktaAuth()
  const [sessionToken, setSessionToken] = useState()
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [isLoggingIn, setIsLoggingIn] = useState(false)

  const login = async () => {
    setIsLoggingIn(true)

    const oktaAuth = new OktaAuth({ issuer: issuer })

    try {
      const res = await oktaAuth.signIn({ username: email, password })
      const sessionToken = res.sessionToken
      setSessionToken(sessionToken)
      // sessionToken is a one-use token, so make sure this is only called once
      authService.redirect({ sessionToken })
    } catch (e) {
      setIsLoggingIn(false)
      SnackbarUtils.error(`${e.errorSummary || e.message}: ${e.errorCode}`)
      console.error("Error logging in", e)
    }
  }

  const handleSubmit = e => {
    e.preventDefault()

    login()
  }

  if (sessionToken) {
    // Hide form while sessionToken is converted into id/access tokens
    return null
  }

  return (
    <Container component="main" maxWidth="md">
      <Section title="Sign In to SimplyVAT templates" fullWidth style={{background: "white", paddingTop: "20px", paddingBottom: "20px"}}>
        <LogoBackground />
        <CircularLoader open={isLoggingIn} color="primary" transitionDuration={0} />
        <Container maxWidth="xs">
          <ValidatorForm onSubmit={handleSubmit}>
            <TextValidator
              spellCheck="false"
              className={classes.formField}
              variant="outlined"
              autoComplete="username"
              autoFocus
              name="email"
              label="Email Address"
              // required
              value={email}
              fullWidth
              onChange={({ target: { value: newValue } }) => setEmail(newValue)}
              validators={["required", "isEmail"]}
              errorMessages={[
                "This field is required!",
                "This must be a valid email address!"
              ]}
            />
            <TextValidator
              className={classes.formField}
              variant="outlined"
              autoComplete="current-password"
              name="password"
              // required
              type="password"
              label="Password"
              value={password}
              fullWidth
              onChange={({ target: { value: newValue } }) => setPassword(newValue)}
              validators={["required"]}
              errorMessages={["This field is required!"]}
            />
            <Button
              type="submit"
              id="submit"
              variant="contained"
              color="primary"
              fullWidth
              className={classes.submitButton}
              disabled={isLoggingIn}
            >
              Login
            </Button>
            <Grid container className={classes.linksContainer}>
              <Grid item xs>
                <Link to="/forgotpassword" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
            </Grid>
          </ValidatorForm>
          <Box className={classes.copyright} mt={8}>
            <Copyright />
          </Box>
        </Container>
      </Section>
    </Container>
  )
}

LoginForm.propTypes = {
  issuer: PropTypes.string.isRequired
}

export default LoginForm

// const LoginForm = props => {
//   return (
//     <ValidatorForm onSubmit={e => this.handleSubmit(e)}>
//       <TextValidator
//         // className={classes.formField}
//         variant="outlined"
//         // style={{padding: "12px"}}
//         autoComplete="username"
//         autoFocus
//         name="username"
//         label="Email"
//         value={username}
//         fullWidth
//         onChange={this.changeTextField()}
//         validators={["required"]}
//         errorMessages={["This field is required!"]}
//       />
//       <TextValidator
//         // className={classes.formField}
//         variant="outlined"
//         autoComplete="current-password"
//         name="password"
//         type="password"
//         label="Password"
//         value={this.state.password}
//         fullWidth
//         onChange={this.changeTextField()}
//         validators={["required"]}
//         errorMessages={["This field is required!"]}
//       />
{
  /* <Alert type="error" visible={error}>
        {error && error.errorCode == "E0000004"
          ? "Invalid username or password"
          : "Unknown error"}
      </Alert>
      <Alert type="success" visible={message}>
        {message}
      </Alert> */
}
{
  /* <ButtonGroup
        className={classes.buttons}
        fullWidth
        color="primary"
        aria-label="small outlined button group"
        variant="outlined"
      > */
}
//       <Button type="submit">Login</Button>
//       {/* <ButtonLink Link="/forgotpassword" text="Forgot my password" /> */}
//       {/* </ButtonGroup> */}
//     </ValidatorForm>
//   )
// }

// export default LoginForm
