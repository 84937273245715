import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';

import { withAPIAccess } from "../api/APIAccess";
import withUser from "../login/UserAccess";

import Button from '@material-ui/core/Button';

import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

import Box from '@material-ui/core/Box';

import Section from "../common/Section";
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/theme-clouds";
import "ace-builds/src-noconflict/ext-language_tools"

import { Document, Page } from "react-pdf/dist/entry.webpack";

import FilesList from "../files/FilesList";

import { download } from "../files/Utils";

import IFrame from "../common/IFrame";

import DialogBox from "../common/DialogBox";

import { pdfjs } from 'react-pdf';
//pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStyles = makeStyles(theme => ({
    page: { backgroundColor: 'red' }
}));

const PreviewPDF = props => {
    const [pageNums, setPageNums] = useState(0);

    const classes = useStyles();

    return <DialogBox {...props} title="Preview">
        <Document
            file={props.previewData}
            onLoadSuccess={({ numPages }) => setPageNums(numPages)}
        >
            {Array.from(Array(pageNums), (_, i) => i + 1).map(x => <Box borderBottom="3px solid black"><Page pageNumber={x} /></Box>)}
        </Document>
    </DialogBox>
}

export default withUser(withAPIAccess(function HTMLTemplate(props) {
    const { api, user, match } = props;

    const { id } = match.params;

    const [loadingFields, setLoadingFields] = useState(false);

    const [files, setFiles] = useState([]);

    const [viewAs, setViewAs] = useState("html");

    const [jsonText, setJSONText] = useState("");
    const [functionsText, setFunctionsText] = useState("");
    const [contentText, setContentText] = useState("");

    const [json, setJSON] = useState({});
    const [html, setHTML] = useState("");
    const [updated, setUpdated] = useState(false);
    const [template, setTemplate] = useState({});
    const [logged, setLogged] = useState("")

    const [downloadLoading, setDownloadLoading] = useState(false);
    const [previewData, setPreviewData] = useState();

    function run() {
        let newJson = json;
        try {
            newJson = JSON.parse(jsonText);
            setJSON(newJson);
        } catch (e) { 
            return setHTML(e.message)
        }

        try {
            return api.templates
                .renderTemplate(id, newJson, true)
                .then(res => {
                    setHTML(res.output);
                    setLogged(res.log.join("\n"))
                    setUpdated(false);
                });
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        api.templates.get(id).then(template => {
            setTemplate(template);
            setJSONText(template.json);
            try {
                setJSON(JSON.parse(template.json));
            } catch (e) { }

            setFunctionsText(template.functions);
            setContentText(template.content);

            setViewAs(template.type === "fill" ? "text" : "html");

            setUpdated(true);
        });
    }, []);

    useEffect(() => {
        run();
    }, [contentText, jsonText, updated]);

    const save = async e => {
        await api.templates.update(id, {
            functions: functionsText,
            json: jsonText,
            content: contentText
        });

        setUpdated(true);
    };

    const previewPDF = async e => {
        await save();
        api.templates
            .getPDF(id, json)
            .then(res => {
                setPreviewData(res);
            });
    };

    const downloadPDF = e => {
        setDownloadLoading(true)
        api.templates
            .getPDF(id, json)
            .then(res => {
                download("Preview_" + id + ".pdf", res, "application/pdf");
                setDownloadLoading(false);
            });
    }

    const viewAsText = e => {
        if (viewAs === "text") {
            setViewAs("html");
        } else {
            setViewAs("text");
        }
    };

    if (!user?.profile)
        return null;

    const getFieldsFromPDF = file => async e => {
        setLoadingFields(true);
        api.pdfs
            .loadFieldsFromFile(file, true)
            .then(res => {
                let updated = res;
                if (html) {
                    let json = JSON.parse(html);

                    for (let key of Object.keys(json)) {
                        if (typeof updated[key] !== "undefined") {
                            updated[key] = json[key];
                        }
                    }
                }

                setContentText(JSON.stringify(updated, null, 1));
                setLoadingFields(false);
            });
    };

    const headers = [
        <Button color={updated ? "secondary" : "inherit"} onClick={save}>Save</Button>
    ];

    const handleChange = e => {
        api.templates.update(template.id, {
            params: {
                base_filename: e.target.value
            }
        }).then(success => {
            setTemplate({
                ...template,
                params: {
                    base_filename: e.target.value
                }
            });
        });
    }

    return [
        <Section fullWidth title={`Template: ${template.name}`} headerControls={headers}></Section>,
        template.type === "fill" ? <Box p={2}><TextField
            id="pdfs"
            name="pdfs"
            label="Select a PDF to fill..."
            select
            fullWidth
            variant="outlined"
            value={template.params.base_filename}
            onChange={handleChange}
            required={true}
        >{files.filter(x => x.name.endsWith(".pdf")).map(x => {
            return <MenuItem value={x.name}>{x.displayName}</MenuItem>
        })}
        </TextField></Box> : null,
        <PreviewPDF open={Boolean(previewData)} previewData={previewData} handleClose={e => {
            setPreviewData(null);
        }} />,
        <FilesList
            title="Assets"
            fullWidth={false}
            prefix={`assets/${id}`}
            onChange={files => {
                setFiles(files);
            }}
        />,
        <Section title="Functions Editor" headerControls={headers}>
            <AceEditor
                mode="javascript"
                theme="clouds"
                name="functions-editor"
                onChange={functions => {
                    setFunctionsText(functions);
                }}
                value={functionsText}
                minLines={10}
                maxLines={30}
                width="100%"
                editorProps={{ $blockScrolling: true }}
                setOptions={{
                    enableBasicAutocompletion: true,
                    enableLiveAutocompletion: true,
                    enableSnippets: true
                }}
            />
        </Section>,
        <Section title="Template Editor" headerControls={
            [...headers,
            template?.params?.base_filename ? <Button onClick={getFieldsFromPDF(template?.params.base_filename)} color="inherit" disabled={loadingFields}>Load fields from PDF</Button> : null
            ]
        }>
            <AceEditor
                mode={template?.type === "fill" ? "json" : "html"}
                theme="clouds"
                onChange={html => {
                    setContentText(html);
                }}
                value={contentText}
                name="html-editor"
                minLines={10}
                maxLines={40}
                width="100%"
                editorProps={{ $blockScrolling: true }}
                setOptions={{
                    enableBasicAutocompletion: true,
                    enableLiveAutocompletion: true,
                    enableSnippets: true
                }}
            />
        </Section>,
        <Section title="JSON Test Request" headerControls={headers}>
            <AceEditor
                mode="json"
                theme="clouds"
                name="json-editor"
                onChange={json => {
                    setJSONText(json);
                }}
                value={jsonText}
                minLines={10}
                maxLines={30}
                width="100%"
                editorProps={{ $blockScrolling: true }}
            />
        </Section>,
        <Section title="Output" headerControls={[
            <Button
                color={viewAs === "text" ? "secondary" : "inherit"}
                onClick={viewAsText}
            >
                {downloadLoading ? "Loading..." : "View as text"}
            </Button>,
            <Button
                disabled={downloadLoading}
                color="inherit"
                onClick={downloadPDF}
            >
                {downloadLoading ? "Loading..." : "Download PDF"}
            </Button>,
            <Button
                disabled={downloadLoading}
                color="inherit"
                onClick={previewPDF}
            >
                {downloadLoading ? "Loading..." : "Preview PDF"}
            </Button>
        ]}>
            {viewAs === "html" && <IFrame style={{ width: "100%", height: "400px" }}>
                <div dangerouslySetInnerHTML={{ __html: html }}></div>
            </IFrame>}

            {viewAs === "text" && <AceEditor
                mode="auto"
                theme="clouds"
                value={html}
                name="output-text"
                minLines={10}
                maxLines={40}
                width="100%"
                readOnly
                editorProps={{ $blockScrolling: true }}
            />}
        </Section>,
        <Section title="Log">
            <AceEditor
                mode="text"
                theme="clouds"
                name="json-editor"
                readOnly
                value={logged}
                minLines={10}
                maxLines={30}
                width="100%"
                editorProps={{ $blockScrolling: true }}
            />
        </Section>,
    ]
}));