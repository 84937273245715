import React from "react";

import { makeStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles(theme => ({
    appBar: {
        top: 'auto',
        bottom: 0,
    },
    toolbar: {
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    title: {

    }
}));


export default function AppToolbar(props) {
    const classes = useStyles();

    return <AppBar position="static" color="primary" className={classes.appBar} elevation={props.elevation}>
        <Toolbar className={classes.toolbar}>
            <div>
                <Typography className={classes.title} component="h2" variant="h6" color="inherit" noWrap>{props.title}</Typography>
            </div>
            <div>
                {props.headerControls}
            </div>
        </Toolbar>
    </AppBar>
}
