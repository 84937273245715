import APIService from "./APIService";

const fetch = require("node-fetch");

export default class KeysAPI {
    constructor(service, api) {
        this.service = service;

        this.api = api;
    }

    async create() {
        return this.service.post("/api-keys").then(res => res.json());
    }

    async get(id) {
        if (typeof id === "undefined") {
            return this.service.get("/api-keys").then(res => res.json());
        } else {
            return this.service.get("/api-keys/" + id).then(res => res.json());
        }
    }

    async delete(id) {
        return this.service.delete("/api-keys/" + id);
    }
}
