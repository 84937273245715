import React, { Suspense } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import TopBar from "./TopBar.jsx";
import LinearProgress from '@material-ui/core/LinearProgress';
import AppToolbar from "./AppToolbar.jsx";

import Box from '@material-ui/core/Box';


const useStyles = makeStyles(theme => ({
    paper: {
        position: "relative",
        margin: theme.spacing(2),
        marginBottom: theme.spacing(5)
    }
}));

export default function Section(props) {
    const classes = useStyles();
    const { title, fullWidth, children, headerControls, elevation, ...rest} = props;

    const toolbar = <AppToolbar elevation={elevation} headerControls={headerControls} title={title} />

    const content = <Box {...rest}>
        {children}
    </Box>
    return fullWidth ? <>
        { title && toolbar }
        {content}
    </>: <Paper className={classes.paper}>
            { title && toolbar }
            {content}
        </Paper>
}
