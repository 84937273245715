import React, { useState, useEffect } from "react";

import { useOktaAuth } from '@okta/okta-react';
import jwt_decode from "jwt-decode";

const UserContext = React.createContext();

function isAdmin(user) {
    return user && user.groups.indexOf("Administrators") !== -1;
}

export const UserAccess = props => {
    const auth = useOktaAuth();

    const [authenticated, setAuthenticated] = useState(null);
    const [profile, setProfile] = useState(null);
    const [token, setToken] = useState(null);

    console.log(auth);

    let authed = auth.authState.isAuthenticated;

    if (authed) {
        if (authed !== authenticated) {
            setAuthenticated(authed);
            Promise.all([
                auth.authState.accessToken,
                auth.authService.getUser()
            ]).then(([t, user]) => {
                if (token !== t) {
                    setProfile(authed ? {...user, ...jwt_decode(t)} : null);
                    setToken(authed ? t : null);
                }
            });
        }
    };

    return <UserContext.Provider value={{
        auth,
        profile,
        token,
    }}>
        {props.children}
    </UserContext.Provider>
}

export default function withUser(Component) {
    return props => {
        return <UserContext.Consumer>
            { (user) => <Component {...props} user={user} />}
        </UserContext.Consumer>
    }
}