const parseSync = require('csv-parse/lib/sync');

export function download(filename, content, type) {
    var blob = new Blob([content], { type });

    var objectUrl = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.style.display = 'none';
    document.body.appendChild(link);

    link.href = objectUrl;
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    link.click();
}

export function readLocalFileHeaders(file) {
    return new Promise((good, bad) => {
        var fileSize   = file.size;
        var chunkSize  = 10 * 1024; // bytes
        var len     = chunkSize;
        var self       = this; // we need a reference to the current object
        var chunkReaderBlock = null;

        var readEventHandler = r => function(evt) {
            let text = evt.target.result;

            let res = parseSync(text, {
                delimiter: file.name.endsWith(".txt") ? "\t" : ",",
                relax_column_count: true,
                relax: true
            });

            if (res.length > 1 || len >= fileSize) {
                good(res[0]);
                return;
            }

            if (evt.target.error != null) {
                bad(evt.target.error);
                return;
            }

            len += chunkSize;

            // of to the next chunk
            chunkReaderBlock(0, len, file);
        }

        chunkReaderBlock = function(_offset, length, _file) {
            var r = new FileReader();
            var blob = _file.slice(_offset, length + _offset);
            r.onload = readEventHandler(r);
            r.readAsText(blob);
        }

        // now let's start the read with the first block
        chunkReaderBlock(0, len, file);
    });
}