import 'date-fns';

import React, { useState, useEffect } from 'react';
import logo from './logo.svg';
import './App.css';

import { SnackbarProvider } from "notistack"

import Container from "./Container.jsx";
import { BrowserRouter as Router, Route, useHistory, Switch } from "react-router-dom";

import {
  MuiThemeProvider,
  createMuiTheme,
  responsiveFontSizes
} from "@material-ui/core/styles"
import CssBaseline from "@material-ui/core/CssBaseline"

import { Redirect } from "react-router-dom"

import Files from "./files/Files";

import DateFnsUtils from '@date-io/date-fns';

import Login from "./login/Login.jsx";

import Template from "./templates/Template";

import TemplatesList from "./templates/TemplatesList"

import {
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import { APIAccess } from "./api/APIAccess";
import { UserAccess } from "./login/UserAccess";

import withUser from "./login/UserAccess";

import { withAuth } from '@okta/okta-react';
import { Security, LoginCallback, SecureRoute } from '@okta/okta-react';

import Logout from "./Logout";

//import CustomImplicitCallback from "./login/CustomImplicitCallback";

const TokenPage = withUser(props => {
  return props.user.token;
});

const InternalRoutes = props => {
  const history = useHistory();

  const config = {
    issuer: `${process.env.REACT_APP_OKTA_URL}/oauth2/${process.env.REACT_APP_OKTA_AUTH_SERVER}`,
    redirectUri: window.location.origin + '/implicit/callback',
    clientId: `${process.env.REACT_APP_OKTA_CLIENT_ID}`,
    pkce: true,
    onAuthRequired() {
      history.push("/login");
    }
  }
  return <Security {...config}>
    <UserAccess>
      <Container drawerWidth={"20%"}>
        <APIAccess>
          <Switch>
            <SecureRoute path="/templates/:id" exact={true} component={Template} />

            <SecureRoute path="/" exact={true} component={TemplatesList} />

            <SecureRoute path="/files" exact={true} component={Files} />

            <SecureRoute path="/_token" exact={true} component={TokenPage} />

            <SecureRoute path="/logout" component={Logout} />
          </Switch>
        </APIAccess>
      </Container>
      <Route path='/implicit/callback' component={LoginCallback} />

      <Route
        path="/login"
        render={props =>
          <Login issuer={config.issuer} {...props} redirectTo="/" />}
      />
    </UserAccess>
  </Security>
}

let theme = createMuiTheme({
  palette: {
    primary: {
      // blue
      main: "rgb(2, 77, 107)",
      light: "rgb(55, 106, 140)",
      dark: "rgb(37 ,64, 83)"
    },
    secondary: {
      // yellow
      main: "rgb(243, 155, 67)",
      light: "rgb(203, 177, 111)",
      dark: "rgb(197, 131, 55)"
    },
    error: {
      // red
      main: "rgb(198, 53, 65)",
      light: "rgb(206, 97, 94)",
      dark: "rgb(159, 57, 59)"
    }
  },
  typography: {
    fontFamily: "Open Sans"
  },
  overrides: {
    MuiTypography: {
      colorTextSecondary: {
        color: null
      }
    },
    MuiListItem: {
      root: {
        "&$selected": {
          backgroundColor: "rgb(2, 77, 107)",
          color: "white"
        }
      }
    }
  }
})

theme = responsiveFontSizes(theme)


function App() {
  const [id, setId] = useState("");

  useEffect(() => {
    fetch("http://localhost/token").then(res => res.text()).then(setId);
  }, []);

  return (
    <div className="App">
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <SnackbarProvider
            preventDuplicate
            maxSnack={7}
            autoHideDuration={10000}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
          >
            <Router>
                <InternalRoutes />
            </Router>
          </SnackbarProvider>

        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    </div>
  );
}

export default App;
