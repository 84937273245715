import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import InboxIcon from '@material-ui/icons/MoveToInbox';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MailIcon from '@material-ui/icons/Mail';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import { withAPIAccess, useAPIAccess } from "../api/APIAccess.jsx";

import { withRouter } from "react-router";

import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import Badge from '@material-ui/core/Badge';
import { useOktaAuth } from '@okta/okta-react';

import ListItemLink from "../common/ListItemLink.jsx";

import update from 'immutability-helper';

import NAVIGATION_LINKS from "./NavLinks.js";

const useStyles = drawerWidth => makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        marginLeft: drawerWidth,
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    primaryText: {
    }
}));

const SidebarContent = withRouter(function (props) {
    const classes = useStyles(props.drawerWidth)();
    const { isAdmin, links, companies, client, user } = props;

    const [anchorEl, setAnchorEl] = useState(null);

    const menuItemClick = client => e => {
        setAnchorEl(null);
        //setClient(client);
        if (props.onClientChange)
            props.onClientChange(client);
    };

    console.log(user);

    return <div>
        <div className={classes.toolbar}>
        </div>
        <Divider />
        <List>
            {links.map((item, index) => {
                if (item.adminOnly && !isAdmin)
                    return null;

                if (!isAdmin && (!client && item.link.includes(":companyId")))
                    return null;

                let link = isAdmin ? item.adminLink : item.link.includes(":companyId") ? item.link.replace(":companyId", client.Id) : item.link;

                let selected = window.location.pathname.includes(link);

                return <ListItemLink selected={selected} button key={item.name} href={link}>
                    <ListItemText primary={item.name} secondary={item.description} classes={{ primary: classes.primaryText }}/>

                    <Badge className={classes.margin} badgeContent={item.updates} color="secondary"></Badge>
                </ListItemLink>
            })}
        </List>
    </div>
});

export default function Sidebar(props) {
    const { container, drawerWidth, companyId, user } = props;
    const classes = useStyles(drawerWidth)();
    const theme = useTheme();
    const api = useAPIAccess();

    const admin = true;

    const [links, setLinks] = React.useState(NAVIGATION_LINKS);
    const [companies, setCompanies] = React.useState([]);
    const [client, setClient] = React.useState(null);

    return <Drawer
        classes={{
            paper: classes.drawerPaper,
        }}
        variant="permanent"
        open>
        <SidebarContent user={user} client={client} drawerWidth={drawerWidth} links={links}/>
    </Drawer>
};
