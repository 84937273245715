import React, { useState, useEffect }from "react";

import SortableTableCRUD from "../common/SortableTableCRUD";

import { useHistory } from "react-router";

import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import TextField from '@material-ui/core/TextField';

import Button from '@material-ui/core/Button';

import { useAPIAccess } from "../api/APIAccess";
import DialogBox from "../common/DialogBox";

import truncate from "truncate";

export default function TemplatesList(props) {
    const api = useAPIAccess();
    const history = useHistory();

    const [templates, setTemplates] = useState(null);
    const [currentTemplate, setCurrentTemplate] = useState(null);
    const [editing, setEditing] = useState(null);

    useEffect(() => {
        api.templates.get().then(templates => {
            setTemplates(templates.sort((a, b) => a.id - b.id));
        });
    }, []);

    const onDialogAdd = values => {
        if (!values)
            return;
            
        api.templates.create({
            name: values.name, 
            description: values.description, 
            type: values.type
        }).then(template => {
            setTemplates([...templates, template]);
            history.push("/templates/" + template.id);
        });
    };

    const deleteTemplate = template => e => {
        api.templates.delete(template.id).then(e => {
            setTemplates(templates.filter(x => x.id != template.id));
            setCurrentTemplate(null);
        });
    }

    const edit = row => e => {
        setEditing(row);
    }

    const save = row => e => {
        api.templates.update(row.id, editing).then(template => {
            setTemplates([...templates.map(x => x.id === editing.id ? editing : x)]);
            setEditing(null);
        });
    }

    const clone = row => async e => {
        let template = await api.templates.get(row.id);
        delete template.id;

        api.templates.create({
            ...template,
            name: row.name + " (Cloned)",
        }).then(template => {
            setTemplates([...templates, template]);
            history.push("/templates/" + template.id);
        });
    }

    const handleChange = (field, row) => e => {
        setEditing({
            ...row,
            [field]: e.target.value
        });
    }

    return <>
        <DialogBox open={currentTemplate} title="Are you sure?" 
            handleClose={e => setCurrentTemplate(null)} 
            actions={[
                <Button onClick={deleteTemplate(currentTemplate)}>Yes</Button>,
                <Button onClick={e => setCurrentTemplate(null)}>No</Button>
            ]}>
            Are you sure you want to delete this item?
        </DialogBox>
        <SortableTableCRUD
            headers={[{
                id: "name",
                label: "Name"
            },
            {
                id: "description",
                label: "Description"
            },
            {
                id: "actions",
                label: "Actions"
            }]}
            loading={templates === null}
            data={templates || []}
            paginate={true}
            paginationStartRows={20}
            paginationOptions={[20, 50].sort()}
            row={(row, index) => {
                return <TableRow>
                    <TableCell title={row.name}>
                        {(editing?.id === row.id) && <TextField
                            onChange={
                                handleChange("name", row)
                            }
                            value={editing.name}
                            variant="outlined"
                        /> }
                        {(editing?.id !== row.id) && truncate(row.name, 50)}
                    </TableCell>
                    <TableCell title={row.description}>
                        {(editing?.id === row.id) && <TextField
                            onChange={
                                handleChange("description", row)
                            }
                            value={editing.description}
                            variant="outlined"
                        /> }
                        {(editing?.id !== row.id) && truncate(row.description, 50)}
                    </TableCell>
                    <TableCell>
                        <Button color="primary" href={"/templates/" + row.id}>View</Button>
                        <Button color="primary" onClick={editing?.id === row.id ? save(row) : edit(row)}>{editing?.id === row.id ? "Save" : "Edit"}</Button>
                        <Button color="primary" onClick={clone(row)}>Clone</Button>
                        <Button color="primary" onClick={e => setCurrentTemplate(row)}>Delete</Button>
                    </TableCell>
                </TableRow>
            }}
            fullWidth
            title="Templates"
            headerControls={[]}
            onDialogAdd={onDialogAdd}
            dialogTitle="Add template"
            dialogFields={[
                {
                    id: "name",
                    label: "Name",
                    required: true
                },
                {
                    id: "description",
                    label: "Description",
                    required: true
                },
                {
                    id: "type",
                    label: "Type",
                    type: "select",
                    data: [
                        {
                            id: "standard",
                            label: "Standard"
                        },
                        {
                            id: "fill",
                            label: "Fill fields"
                        }
                    ]
                }
            ]}
        />
    </>
};
