import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Avatar from '@material-ui/core/Avatar';
import MenuIcon from '@material-ui/icons/Menu';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import MoreIcon from '@material-ui/icons/MoreVert';

import AppToolbar from "./AppToolbar.jsx";
import SortableTable from "./SortableTable.jsx";
import Section from "./Section.jsx";

const styles = theme => ({
    paper: {
        position: "relative",
        margin: theme.spacing(2),
        marginBottom: theme.spacing(5)
    },
});

class SortableTableWithHeader extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { classes, children } = this.props;

        return <Section {...this.props}>
            <SortableTable {...this.props} />
            { children || [] }
        </Section>
    }
}

export default withStyles(styles)(SortableTableWithHeader);
